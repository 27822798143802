import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectServicesVersions } from "../../features/Dashboard/Slices/dashboardSlice";
import { useAppDispatch } from "../../app/store";
import TableList from "../../components/TableList/TableList";
import Button from "../../components/reusable/Button/Button";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import { getServicesVersions } from "../../features/Dashboard/AsyncThunks/servicios/getServicesStatus";
import RepoTimeline from "../../components/GitLabTimeline/RepoTimeline";

function Servicios() {
  const servicesVersions = useSelector(selectServicesVersions);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const getServicesData = async () => {
    setLoading(true);
    await dispatch(getServicesVersions());
    setLoading(false);
  };

  useEffect(() => {
    getServicesData();
  }, []);

  return (
    <div className="p-6 grid grid-cols-4 gap-6">
      {loading && <PageLoader />}

      <div className="bg-white p-6 rounded-lg col-span-3 border-2 border-cardBorder overflow-x-hidden">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Versiones de servicios
        </h4>
        <button
          className="text-textPrimary font-bold hover:underline border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
          onClick={getServicesData}
        >
          Actualizar
        </button>
        <div className="col-span-4 grid grid-cols-3 lg:grid-cols-4 gap-2 text-xs pt-3">
          {servicesVersions.map((repo: any) => (
            <p
              className={`rounded-lg bg-primary border-[3px] border-primary flex flex-nowrap items-center max-h-6 overflow-hidden`}
            >
              <span className="bg-white rounded-md min-w-[55px] text-center font-semibold flex-grow whitespace-nowrap truncate overflow-hidden">
                {repo.name}
              </span>
              <span className={`px-1 text-[10px] text-white`}>
                {repo.environment}
              </span>
              <span className="bg-white px-1 rounded-full">{repo.version}</span>
            </p>
          ))}
        </div>

        {/* <TableList list={servicesVersions} /> */}
      </div>

      <div className="bg-white p-6 rounded-lg col-span-4 border-2 border-cardBorder overflow-x-scroll">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Ambientes
        </h4>
        <div className="min-w-[920px] relative overflow-hidden">
          <div className="z-0 absolute flex h-screen font-bold">
            <div className="border-2 w-[300px] invisible"></div>
            <div className="border-l-2 border-[#ffffff] w-[150px] h-full bg-[#eef2ff]">
              <h2 className="text-center pt-4">DEVELOP</h2>
            </div>
            <div className="border-l-2 border-[#ffffff] w-[150px] h-full bg-[#e0e7ff]">
              <h2 className="text-center pt-4">TESTING</h2>
            </div>
            <div className="border-l-2 border-[#ffffff] w-[150px] h-full ">
              <h2 className="text-center pt-4">STAGING</h2>
            </div>
            <div className="border-l-2 border-[#ffffff] w-[150px] h-full bg-[#6366f1]">
              <h2 className="text-center pt-4 text-white">PRODUCTION</h2>
            </div>
          </div>

          <div className="z-10 mt-10 relative space-y-6 py-4">
            {/* Frontend */}
            <RepoTimeline projectId={406} />
            {/* Dashboard */}
            <RepoTimeline projectId={419} />
            {/* Notifications */}
            <RepoTimeline projectId={411} />
            {/* Api */}
            <RepoTimeline projectId={407} />
            {/* Sync */}
            <RepoTimeline projectId={410} />
            {/* Proxy */}
            <RepoTimeline projectId={404} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Servicios;
