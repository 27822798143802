import React from "react";
import "./Button.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

export type ButtonTheme =
  | "primary"
  | "red"
  | "white"
  | "blue"
  | "yellow"
  | "green";
interface Props {
  label?: string;
  onClick?: () => void;
  className?: string | undefined;
  children?: any;
  theme?: ButtonTheme;
  iconName?: string;
  disabled?: boolean;
}

function Button(props: Props) {
  const {
    onClick = () => {},
    label = "",
    className = "",
    children,
    theme = "primary",
    iconName = "",
    disabled = false,
  } = props;

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <button
      className={`buttonProps generalBtn ${theme} ${className} ${
        disabled ? "disableBtn" : ""
      }`}
      onClick={handleClick}
      disabled={disabled}
    >
      {label}
      {children}
      {iconName.length > 0 && (
        <SlIcon name={iconName} className="button-icon" />
      )}
    </button>
  );
}

export default Button;
