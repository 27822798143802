import { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/store";
import { getDetalleDeCurso } from "../../features/Dashboard/AsyncThunks/cursos/getDetalleCurso";
import { useSelector } from "react-redux";
import { selectDetalleCurso, setCleanDetalleCurso } from "../../features/Dashboard/Slices/dashboardSlice";
import { InfoMirros } from "../../types";
import { parseHours } from "../../utils";
import JsonViewer from "../../components/reusable/JsonViewer/JsonViewer";
import TableList from "../../components/TableList/TableList";

export default function Cursos() {
  const [loading, setLoading] = useState(false);
  const [idCurso, setIdCurso] = useState<string>("");
  const detalleCurso = useSelector(selectDetalleCurso);

  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   return () => {
  //     dispatch(setCleanDetalleCurso())
  //   }
  // }, [])
  

  const handleDetalleCurso = async (idCurso: number) => {
    if (idCurso) {
      setLoading(true);
      await dispatch(getDetalleDeCurso(idCurso));
      setIdCurso("");
      setLoading(false);
    } else {
      // toastInfo("Ingrese idCupo");
    }
  };

 const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
   const value = e.target.value;
   // Validar que solo sean números positivos o vacío
   if (/^\d*$/.test(value)) {
     setIdCurso(value);
   }
 };

 const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
   event.preventDefault();
   const cursoId = Number(idCurso);
   if (cursoId > 0) {
     handleDetalleCurso(cursoId);
   } else {
     alert("ID no válido");
   }
 };

  return (
    <div className="p-6 flex items-start gap-6">
      <div className="bg-white p-6 rounded-lg border-2 border-cardBorder w-3/4">
        <div className="flex items-center justify-between">
          <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
            Cursos
          </h4>
          <form className="flex" onSubmit={handleSubmit}>
            <input
              value={idCurso}
              onChange={handleChangeInput}
              type="text"
              placeholder="id curso #"
              className="border-2 border-cardBorder rounded-l-lg rounded-r-none px-2"
            />
            <button
              type="submit"
              className="ml-[-3px] z-[1] w-28 text-white bg-primary rounded-r-md"
            >
              Buscar
            </button>
          </form>
        </div>

        {detalleCurso && (
          <div className="border-[1px] border-secondary p-3 my-4 space-y-4">
            {/* --------------------------------- titulo ---------------------------------  */}
            <div className="col-span-4 bg-[#eee] flex justify-between p-2">
              <h3 className="font-semibold text-[14px]">
                <span className="text-secondary font-normal mr-3">
                  #{detalleCurso?.curso.N_CURSO}
                </span>
                {detalleCurso?.curso.D_DESCRIP}
              </h3>
              <h3 className="font-semibold text-[14px]">
                <span className="ml-4 text-secondary font-normal">Año </span>
                {detalleCurso?.curso.C_AÑO_LECTIVO}
              </h3>
            </div>

            {/*  --------------------------------- fechas ---------------------------------  */}
            <div className="">
              <h4 className="font-bold pb-2">Fechas</h4>
              <div className="flex justify-center items-center gap-6">
                <div className="flex-grow">
                  <p className="border-b-[1px] border-cardBorder">Fecha inicio</p>
                  <h5 className="">
                    {new Date(detalleCurso.curso.F_INICIO).toLocaleDateString()}
                  </h5>
                </div>
                <div className="flex-grow">
                  <p className="border-b-[1px] border-cardBorder">Fecha cierre</p>
                  <h5 className="">
                    {new Date(detalleCurso.curso.F_CIERRE).toLocaleDateString()}
                  </h5>
                </div>
                <div className="flex-grow">
                  <p className="border-b-[1px] border-cardBorder">Fecha hasta</p>
                  <h5 className="">
                    {new Date(detalleCurso.curso.F_FIN).toLocaleDateString()}
                  </h5>
                </div>
                <div className="flex-grow">
                  <p className="border-b-[1px] border-cardBorder">Fecha alta</p>
                  <h5 className="">
                    {new Date(detalleCurso.curso.F_ALTA).toLocaleDateString()}
                  </h5>
                </div>
                <div className="flex-grow">
                  <p className="border-b-[1px] border-cardBorder">Fecha actualización</p>
                  <h5 className="">
                    {new Date(
                      detalleCurso.curso.F_ACTUALIZAC
                    ).toLocaleDateString()}
                  </h5>
                </div>
                <div className="flex-grow">
                  <p className="border-b-[1px] border-cardBorder">Fecha comienzo</p>
                  <h5 className="">
                    {new Date(
                      detalleCurso.curso.F_COMIENZO
                    ).toLocaleDateString()}
                  </h5>
                </div>
              </div>
            </div>

            {/*  ------------------------------ observaciones -----------------------------  */}
            <div>
              <h4 className="font-bold pb-2">Observaciones</h4>
              <p className="px-3">
                {detalleCurso.curso.D_OBSERVACIONES
                  ? detalleCurso.curso.D_OBSERVACIONES
                  : "- sin observaciones -"}
              </p>
            </div>
            {/*  -------------------------------- horarios -------------------------------- */}
            <div>
              <h4 className="font-bold pb-2">Horarios</h4>
              <CursosHorarios horarios={detalleCurso.infoMirros} />
            </div>
            {/*  -------------------------------- cupos -------------------------------- */}
            <div>
              <h4 className="font-bold pb-2">Cupos</h4>
              <TableList list={detalleCurso.cupos} enableSearchbar={false} />
            </div>
          </div>
        )}
      </div>

      {detalleCurso?.kv && (
        <div className="w-1/4">
          <JsonViewer json={detalleCurso?.kv} />
        </div>
      )}
    </div>
  );
}

/* -------------------------------- HORARIOS -------------------------------- */
interface ICursosProps {
  horarios: InfoMirros[];
}
function CursosHorarios({ horarios }: ICursosProps) {
  return (
    <table className="table-auto w-full border-[1px] border-cardBorder border-collapse">
      <thead>
        <tr className="border-[1px] border-cardBorder">
          <th>ID</th>
          <th>Dia</th>
          <th>Tipo clase</th>
          <th>Desde</th>
          <th>Hasta</th>
          <th>Aula</th>
          <th>Profesor</th>
        </tr>
      </thead>
      <tbody>
        {horarios.map((horario) => (
          <tr className="border-[1px] border-cardBorder">
            <td className="p-1">{horario.N_ID_HORARIO}</td>
            <td className="p-1">{horario.C_DIA_SEMANAL}</td>
            <td className="p-1">{horario.C_TIPO_CLASE}</td>
            <td className="p-1">{parseHours(horario.C_HORA_DESDE)} hs</td>
            <td className="p-1">{parseHours(horario.C_HORA_HASTA)} hs</td>
            <td className="p-1">{horario.AULA}</td>
            <td className="p-1">
              {horario.APELLIDO_PROFESOR},{horario.NOMBRE_PROFESOR} (
              {horario.C_ROL})
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
