import React, { useEffect, useState } from "react";
import "./EstadoGeneral.css";
import {
  selectEstadoTablasMirror,
  setLoadingPageScreen,
} from "../../features/Dashboard/Slices/dashboardSlice";
import { useAppDispatch } from "../../app/store";
import { useSelector } from "react-redux";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { toastError, toastSuccess } from "../../utils/toast";
import {
  updateSincronizacionTabla,
  checkSyncCountTablaMirror,
  checkSyncTablasMirror,
  getEstadoTablasMirror,
  postActualizarCupos,
  getActualizarTablasMirror,
} from "../../features/Dashboard/AsyncThunks/estadoGeneral/getEstadoTablasMirror";
import ModalConfirmation from "../../components/reusable/ModalConfirmation/ModalConfirmation";
import SlProgressRing from "@shoelace-style/shoelace/dist/react/progress-ring";

function EstadoGeneral() {
  const dispatch = useAppDispatch();
  const estadoTablasMirror = useSelector(selectEstadoTablasMirror);
  const confirmationModalInitialState = {
    show: false,
    title: "",
    dialog: "",
    onConfirm: () => {},
  };
  const [confirmationModal, setConfirmationModal] = useState(
    confirmationModalInitialState
  );
  const [loadingTable, setLoadingTable] = useState("");
  const [descriptionBtn, setDescriptionBtn] = useState<number | null>(null);

  const countTablesInicializada = estadoTablasMirror.reduce(
    (acc: number, current: any) => {
      return current.estado === "INICIALIZADA" ? acc + 1 : acc;
    },
    0
  );
  const countTablesDesactivada = estadoTablasMirror.reduce(
    (acc: number, current: any) => {
      return current.estado === "DESACTIVADA" ? acc + 1 : acc;
    },
    0
  );
  const porcentajeInicializadas = Math.round(
    (countTablesInicializada * 100) / estadoTablasMirror.length
  );

  const clearConfirmationModal = () => {
    setConfirmationModal(confirmationModalInitialState);
  };

  const setLoading = (newState: boolean) => {
    dispatch(setLoadingPageScreen(newState));
  };

  useEffect(() => {
    actualizarTablasMirror();
  }, []);

  const inicializarConfiguracion = async () => {
    setLoading(true);
    try {
      await dashboardAPI.inicializarConfiguracion();
    } catch (error) {
      toastError();
    }
    setLoading(false);
    clearConfirmationModal();
  };

  /* ------------------------ Metodos Acciones botones ------------------------ */
  
  const buildIndexes = async () => {
    setLoading(true);
    try {
      await dashboardAPI.buildIndexes();
      toastSuccess("Solicitud enviada");
    } catch (error) {
      toastError();
    }
    setLoading(false);
    clearConfirmationModal();
  };

  const syncInfoTablas = async () => {
    setLoading(true);
    try {
      await dashboardAPI.syncInfoTablas();
      toastSuccess("Diccionario de tablas sincronizado con éxito");
    } catch (error) {
      toastError();
    }
    setLoading(false);
    clearConfirmationModal();
  };

  const sincronizarSiguienteTabla = async () => {
    setLoading(true);
    try {
      await dashboardAPI.sincronizarSiguienteTabla();
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  const borrarTodasLasTablas = async () => {
    setLoading(true);
    try {
      await dashboardAPI.deleteAllTables();
    } catch (error) {
      toastError();
    }
    setLoading(false);
    clearConfirmationModal();
  };
  const dropTables = async () => {
    setLoading(true);
    try {
      await dashboardAPI.dropAllTables();
    } catch (error) {
      toastError();
    }
    setLoading(false);
    clearConfirmationModal();
  };
  const actualizarSincronizacionCompleta = async () => {
    await dispatch(getActualizarTablasMirror());
  };
  const actualizarTablasMirror = async () => {
    await dispatch(getEstadoTablasMirror());
  };
  const revisarSincronizacionTablasMirror = async () => {
    await dispatch(checkSyncTablasMirror());
  };
  const actualizarCupos = async () => {
    await dispatch(postActualizarCupos());
  };
  
  const actualizarSincronizacionTabla = async (tabla: string) => {
    setLoading(true);
    try {
      await dispatch(updateSincronizacionTabla(tabla));
      await actualizarTablasMirror();
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  const revisarSincronizacionDeTabla = async (tabla: string) => {
    setLoading(true);
    try {
      await dispatch(checkSyncCountTablaMirror(tabla));
      await actualizarTablasMirror();
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };
  /* ------------------------------------ . ----------------------------------- */
  const btnsAccionesGlobales = [
    {
      label: "Build Indexes",
      description: "Actualiza los índices de la base de datos.",
      action: buildIndexes,
    },
    {
      label: "Sync info tablas",
      description: "1. Sincroniza la informacion de todas las tablas.",
      action: syncInfoTablas,
    },
    {
      label: "Reset configuracion",
      description: "2. Sincroniza la informacion de todas las tablas.",
      action: () => {
        setConfirmationModal({
          show: true,
          onConfirm: inicializarConfiguracion,
          title: "Desea resetear la configuración?",
          dialog: "",
        });
      },
    },
    {
      label: "Sincronizar Siguiente Tabla",
      description: "3. Sincroniza la informacion de todas las tablas.",
      action: sincronizarSiguienteTabla,
    },
    {
      label: "Borrar toda la información",
      description: "4. Sincroniza la informacion de todas las tablas.",
      action: () => {
        setConfirmationModal({
          show: true,
          onConfirm: borrarTodasLasTablas,
          title: "¿Desea borrar toda la información?",
          dialog: "Se perderán todos los registros",
        });
      },
    },
    {
      label: "Elimina (DROP) todas las tablas",
      description: "5. Sincroniza la informacion de todas las tablas.",
      action: () => {
        setConfirmationModal({
          show: true,
          onConfirm: dropTables,
          title: "¿Desea borrar todas las tablas?",
          dialog: "Se perderán todos los registros",
        });
      },
    },
    {
      label: "Revisar sincronización",
      description:
        "6. Revisa el estado de sincronización de las tablas mirror.",
      action: revisarSincronizacionTablasMirror,
    },
     {
      label: "Corregir sincronización",
      description:
        "Actualiza las tablas que se encuentren desactualizadas.",
      action: actualizarSincronizacionCompleta,
    },
    {
      label: "Refrescar",
      description: "7. Actualiza la información de pantalla.",
      action: actualizarTablasMirror,
    },
  ];

  useEffect(() => {
    console.log("estadoTablasMirror :>> ", estadoTablasMirror);
  }, [estadoTablasMirror]);

  const populateTable = async (endpoint: string) => {
    try {
      setLoading(true);
      setLoadingTable(endpoint);
      const result = await dashboardAPI.inicializarTablaMirror(endpoint);
      console.log("populateTable result :>> ", result);
      toastSuccess(`Se inicializó correctamente la tabla ${endpoint}`);
      actualizarTablasMirror();
    } catch (error) {
      toastError("Ha ocurrido un error inesperado...");
      console.log("error :>> ", error);
    } finally {
      setLoading(false);
      setLoadingTable("");
    }
  };
  const handleDesactivada = (clave: string) => {
    populateTable(clave);
    clearConfirmationModal();
  };

  const handleShowDetailInfo = (descIndex: number | null) => {
    setDescriptionBtn(descIndex);
  };

  const renderCustomTable = () => {
    if (!estadoTablasMirror[0]) {
      return <></>;
    }
    const titles = Object.keys(estadoTablasMirror[0]);
    const populateKeyName = "populateEndpoint";
    return (
      <div className="w-full">
        <div className="grid grid-cols-10 py-1 text-[10px] border-b-2 border-secondary font-semibold">
          <div className="col-span-2 text-center flex justify-center items-center">
            Tabla
          </div>
          <div className="col-span-1 text-center">
            <p className="flex flex-col justify-center items-center">
              <span>Registros</span>
              <span>Oracle</span>
            </p>
          </div>
          <div className="col-span-1 text-center justify-center items-center">
            <p className="flex flex-col">
              <span>Registros</span>
              <span>obtenidos</span>
            </p>
          </div>
          <div className="col-span-1 text-center justify-center items-center">
            <p className="flex flex-col">
              <span>Fecha</span>
              <span>sincronización</span>
            </p>
          </div>
          <div className="col-span-1 text-center justify-center items-center">
            <p className="flex flex-col">
              <span>Fecha</span>
              <span>revisión</span>
            </p>
          </div>
          <div className="col-span-1 text-center justify-center items-center">
            <p className="flex flex-col">
              <span>Fecha</span>
              <span>actualización</span>
            </p>
          </div>
          <div className="col-span-1 text-center justify-center items-center">
            Estado
          </div>
          <div className="col-span-1 text-center justify-center items-center">
            Sync
          </div>
          <div className="col-span-1 text-center justify-center items-center">
            <p className="flex flex-col">
              <span>Revisar</span>
              <span>Contadores</span>
            </p>
          </div>
        </div>
        <div className="flex flex-row flex-1">
          <div className="flex flex-col flex-grow">
            {estadoTablasMirror?.map((item: any) => {
              const rows = titles.map((keyOfObject) => {
                const desactivada = item["estado"] === "DESACTIVADA";
                const noInicializada = item["estado"] !== "INICIALIZADA";
                if (keyOfObject === populateKeyName) {
                  return (
                    <button
                      title={`Sincronizar tabla ${item.clave}`}
                      disabled={desactivada || loadingTable === item.clave}
                      className={`flex hover:opacity-80 shadow-md items-center gap-2 justify-center bg-accent text-white rounded-full disabled:cursor-not-allowed ${
                        noInicializada ? "opacity-50" : ""
                      }`}
                      onClick={() =>
                        noInicializada
                          ? setConfirmationModal({
                              show: true,
                              onConfirm: () => handleDesactivada(item.clave),
                              title: "¿Seguro desea sincronizar?",
                              dialog: "",
                            })
                          : populateTable(item.clave)
                      }
                    >
                      <SlIcon
                        name="arrow-repeat"
                        className={`${
                          loadingTable === item.clave ? "animate-spin" : ""
                        }`}
                      ></SlIcon>
                      Sincronizar
                    </button>
                  );
                } else {
                  return (
                    <p
                      className={`${
                        keyOfObject === "clave" ? "col-span-2" : "text-center"
                      } ${desactivada ? "text-red" : ""}`}
                    >
                      {item[keyOfObject] || ""}

                      {desactivada && keyOfObject === "clave" && (
                        <span className="w-2 h-2 bg-red rounded-full inline-block ml-2"></span>
                      )}
                    </p>
                  );
                }
              });
              return (
                <div className="grid grid-cols-9 py-1 border-b-[1px] border-cardBorder">
                  {rows}
                </div>
              );
            })}
          </div>
          <div className="flex flex-col w-40">
            {estadoTablasMirror?.map((item) => {
              return (
                <div className="flex py-1 border-b-[1px] border-cardBorder justify-center items-center">
                  <button
                    className={`flex flex-1 ml-2 items-center justify-center bg-blue shadow-md text-white rounded-full hover:opacity-80`}
                    onClick={() => revisarSincronizacionDeTabla(item.clave)}
                    title={`Revisar contadores de ${item.clave}`}
                  >
                    <SlIcon className="pr-2" name="hdd-stack"></SlIcon>
                    Revisar
                  </button>
                  {item.clave === "CORE_CUPOS" ? (
                    <button
                      className={`flex flex-1 ml-2 items-center justify-center bg-blue shadow-md text-white rounded-full hover:opacity-80`}
                      onClick={() => actualizarSincronizacionTabla(item.clave)}
                      title={`Revisar contadores de ${item.clave}`}
                    >
                      <SlIcon className="pr-2" name="hdd-stack"></SlIcon>
                      Actualizar
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>           
        </div>
      </div>
    );
  };

  return (
    <div className="p-6 grid grid-cols-4 gap-6">
      <ModalConfirmation
        show={confirmationModal.show}
        onClose={() => setConfirmationModal(confirmationModalInitialState)}
        onConfirm={confirmationModal.onConfirm}
        title={confirmationModal.title}
        message={confirmationModal.dialog}
      />

      <div className="bg-white p-6 rounded-lg border-2 border-cardBorder col-span-1">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Tablas inicializadas
        </h4>
        <div className="grid grid-cols-2">
          <SlProgressRing
            className=""
            value={porcentajeInicializadas}
            style={{
              //@ts-ignore
              "--track-width": "11px",
              "--indicator-width": "12px",
              "--track-color": "#ECEEF2",
              "--indicator-color": "#00C4B3",
            }}
          >
            {porcentajeInicializadas}%
          </SlProgressRing>

          <div className="text-right">
            <p className="font-semibold text-lg">
              {countTablesInicializada}{" "}
              <span className="text-textSecondary">
                / {estadoTablasMirror.length}
              </span>
            </p>
            <p className="text-xs text-secondary">
              {countTablesDesactivada} Tablas Desactivadas
            </p>
          </div>
        </div>
      </div>
      {/* /* -------------------------------------------------------------------------- */
      /*                                   BOTONES                                  */
      /* -------------------------------------------------------------------------- */}
      <div className="bg-white p-6 rounded-lg border-2 border-cardBorder col-span-3 flex flex-col">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Acciones globales
        </h4>
        <div className="flex flex-wrap gap-2">
          {btnsAccionesGlobales.map((btn, index) => {
            return (
              <button
                className="text-textPrimary font-bold hover:underline border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
                onClick={btn.action}
                onMouseEnter={() => handleShowDetailInfo(index)}
                onMouseLeave={() => handleShowDetailInfo(null)}
              >
                {btn.label}
              </button>
            );
          })}
        </div>
        <div className="flex-grow flex flex-col justify-end">
          <p className="flex items-center gap-2 text-textSecondary">
            <SlIcon name="info-circle"></SlIcon>
            {descriptionBtn === null ? (
              <>Descripción de la acción al pasar sobre el botón.</>
            ) : (
              <>
                <span className="font-semibold uppercase">
                  {btnsAccionesGlobales[descriptionBtn].label}:
                </span>
                {btnsAccionesGlobales[descriptionBtn].description}
              </>
            )}
          </p>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg col-span-4 border-2 border-cardBorder">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Estado general de tablas
        </h4>
        {renderCustomTable()}
      </div>
    </div>
  );
}

export default EstadoGeneral;
