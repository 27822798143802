import React, { useEffect, useState } from "react";
import TableList from "../../components/TableList/TableList";
import { dashboardAPI } from "../../Api/dashboardAPI";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import Pagination, {
  defaultPaginationConfig,
} from "../../components/reusable/Pagination/Pagination";
import { toastError, toastSuccess } from "../../utils/toast";
import Button from "../../components/reusable/Button/Button";
import { Novedad, NovedadBitacora } from "../../types/novedad";
import Switch from "../../components/Switch/Switch";
import { useAppDispatch } from "../../app/store";
import {
  selectFiltrarNovedadesConErrores,
  selectParametros,
  setFiltrarNovedadesConErrores,
  setLoadingPageScreen,
} from "../../features/Dashboard/Slices/dashboardSlice";
import { getParametros } from "../../features/Dashboard/AsyncThunks/parametros/getParametros";
import { useSelector } from "react-redux";
import { Parametro } from "../Parametros/Parametros";
import Input from "../../components/reusable/Input/Input";
import AlertInfo from "../../components/reusable/Alerts/AlertInfo";
import "./Novedades.css";
import ModalConfirmation from "../../components/reusable/ModalConfirmation/ModalConfirmation";
import Divider from "../../components/reusable/Divider/Divider";

function Novedades() {
  const [novedades, setNovedades] = useState<any>();
  const [showConfirmClear, setShowConfirmClear] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState<{
    onConfirm: () => void;
    title: string;
    message: string;
  } | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [totalRows, setTotalRows] = useState(10);
  const dispatch = useAppDispatch();
  const parametros = useSelector(selectParametros);
  const filtrarNovedadesConErrores = useSelector(
    selectFiltrarNovedadesConErrores
  );
  const [currentSeccion, setCurrentSeccion] = useState<
    "Pendientes" | "Procesadas"
  >("Pendientes");

  const [filtrarProcesadosConError, setFiltrarProcesadosConError] =
    useState(false);

  const setLoading = (newState: boolean) => {
    dispatch(setLoadingPageScreen(newState));
  };
  const procesarManualmente = parametros.some(
    (p) =>
      p.clave === Parametro.PROCESAR_NOVEDADES_ORACLE_MANUALMENTE &&
      p.valor === "true"
  );
  const descartarNovedades = parametros.some(
    (p) =>
      p.clave === Parametro.DESCARTAR_NOVEDADES_ORACLE && p.valor === "true"
  );

  const obtenerParametros = async () => {
    await dispatch(getParametros());
  };

  const updateParametro = async (paramToEdit: string, newValue: boolean) => {
    setLoading(true);
    try {
      await dashboardAPI.updateParametro(
        paramToEdit,
        newValue ? "true" : "false"
      );
      toastSuccess(`Parámetro ${paramToEdit} actualizado correctamente`);
      obtenerParametros(); // Refresh parametros
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  const buscarNovedadesOracle = async () => {
    setLoading(true);
    try {
      await dashboardAPI.buscarNovedadesOracle();
      getNewNovedades();
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  const borrarNovedad = async (novedad: Novedad) => {
    setLoading(true);
    try {
      const res = await dashboardAPI.deleteNovedad(novedad.N_ID_NOV_INSCRIP);
      toastSuccess(res);
      getNewNovedades();
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  const borrarNovedades = async () => {
    setShowModalConfirm(null);
    setLoading(true);
    try {
      const res = await dashboardAPI.deleteNovedades();
      toastSuccess(res);
      getNewNovedades();
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  const getNewNovedades = async (newPage?: number) => {
    setLoading(true);
    try {
      const newData = await dashboardAPI.getNovedadesPaginated(
        pageSize,
        newPage
          ? (newPage - 1) * pageSize
          : defaultPaginationConfig.startOffset,
        currentSeccion === "Pendientes",
        filtrarProcesadosConError
      );
      if (newPage) {
        setCurrentPage(newPage);
      } else {
        setCurrentPage(1);
      }
      setNovedades(newData.data);
      setTotalRows(newData.totalCount);
    } catch (error) {
      toastError();
    }
    setLoading(false);
  };

  const procesarNovedad = async (novedad: Novedad) => {
    setLoading(true);
    try {
      const res = await dashboardAPI.procesarNovedad(novedad);
      console.log("procesarNovedad - response:", res);
      toastSuccess(
        `Novedad con id: ${novedad.N_ID_NOV_INSCRIP} en cola para ser procesada!`
      );
    } catch (error) {
      console.log("error:", error);
    }
    setLoading(false);
  };

  const actualizarNovedadAPendiente = async (novedadId: number) => {
    setLoading(true);
    try {
      const res = await dashboardAPI.setNovedadPendiente(novedadId);
      console.log("procesarNovedad - response:", res);
      toastSuccess(
        `Novedad con id: ${novedadId} actualizada a estado "PENDIENTE"!`
      );
    } catch (error) {
      toastError();
      console.log("error:", error);
    } finally {
      await getNewNovedades();
      setLoading(false);
    }
  };

  const reprocesarNovedadesConError = async () => {
    setShowModalConfirm(null);
    setLoading(true);
    try {
      const res = await dashboardAPI.reprocesarNovedadesConError();
      console.log("reprocesarNovedadesConError - response:", res);
      toastSuccess(
        `Novedades con error actualizadas en Oracle a estado "PENDIENTE"!`
      );
      getNewNovedades();
    } catch (error: any) {
      console.log("resetearNovedadesFallidas error:", error);
      toastError(error?.message);
    }
    setLoading(false);
  };

  const descartarNovedad = async (novedad: Novedad) => {
    setLoading(true);
    try {
      const res = await dashboardAPI.descartarNovedad(novedad);
      console.log("descartarNovedad - response:", res);
    } catch (error) {
      console.log("error:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getNewNovedades();
  }, [filtrarProcesadosConError]);

  useEffect(() => {
    getNewNovedades();
  }, [currentSeccion]);

  useEffect(() => {
    getNewNovedades();
    obtenerParametros();
  }, []);

  return (
    <div>
      <ModalConfirmation
        show={!!showModalConfirm}
        onClose={() => setShowModalConfirm(null)}
        onConfirm={() => showModalConfirm?.onConfirm()}
        title={showModalConfirm?.title}
        message={showModalConfirm?.message}
        topBorderWarn
      />

      <div className="px-8 py-5">
        <div className="pl-3">
          <strong className="text-lg">Novedades</strong>
          <p className="text-sm">
            En el siguiente listado se muestran las últimas novedades ordenadas
            de manera descendente por su fecha de actualización
          </p>
          <div className="flex flex-row items-center mt-2">
            <Button
              label="Actualizar listado"
              iconName="arrow-clockwise"
              onClick={() => getNewNovedades(1)}
            />
            <Button
              className="ml-3"
              label="Buscar novedades en Oracle"
              iconName="search"
              onClick={() => buscarNovedadesOracle()}
              theme="blue"
            />
            <Button
              className="ml-3"
              label="Limpiar bitacora de novedades"
              iconName="trash"
              onClick={() =>
                setShowModalConfirm({
                  onConfirm: () => borrarNovedades(),
                  title: "¿Seguro que desea limpiar bitacora novedades?",
                  message: "Se eliminaran todos los registros",
                })
              }
              theme="red"
            />
          </div>
          <div className="pt-5">
            <Switch
              checked={procesarManualmente}
              onChange={() =>
                updateParametro(
                  Parametro.PROCESAR_NOVEDADES_ORACLE_MANUALMENTE,
                  !procesarManualmente
                )
              }
              label="Procesar manualmente"
            />
            <Switch
              className={"pl-3"}
              checked={descartarNovedades}
              onChange={() =>
                updateParametro(
                  Parametro.DESCARTAR_NOVEDADES_ORACLE,
                  !descartarNovedades
                )
              }
              label="Descartar novedades"
            />
          </div>
          <div className="flex flex-row items-center mt-3">
            <strong>Registros por página:</strong>
            <Input
              className="pl-2"
              defaultValue={String(pageSize)}
              type="number"
              onChange={(value) => setPageSize(Number(value))}
            />
          </div>
          <Divider className="mt-2.5 mb-2.5" />
          <div className="flex flex-row">
            <Button
              label="Reprocesar novedades con error y pendientes"
              iconName="arrow-repeat"
              onClick={() =>
                setShowModalConfirm({
                  onConfirm: () => reprocesarNovedadesConError(),
                  title: "¿Seguro que desea re procesar novedades con error?",
                  message:
                    "Se actualizaran en Bitácora todas las novedades con error a estado 'PENDIENTE' y se encolaran para ser procesadas nuevamente",
                })
              }
              theme="green"
            />
            {currentSeccion === "Procesadas" && (
              <div>
                <div className="flex flex-row items-center mt-1 mb-2 ml-5">
                  <Switch
                    checked={filtrarProcesadosConError}
                    onChange={() => {
                      setFiltrarProcesadosConError(!filtrarProcesadosConError);
                    }}
                    label="Filtrar procesadas con error"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col pt-3 items-center">
          <div className="w-full flex flex-row justify-around">
            <div
              className={`sectionBtn ${
                currentSeccion === "Pendientes" ? "selected" : ""
              }`}
              onClick={() => setCurrentSeccion("Pendientes")}
            >
              <strong>Pendientes</strong>
            </div>
            <div
              className={`sectionBtn ${
                currentSeccion === "Procesadas" ? "selected" : ""
              }`}
              onClick={() => setCurrentSeccion("Procesadas")}
            >
              <strong>Procesadas</strong>
            </div>
          </div>
          <div className="w-full">
            {novedades?.length > 0 ? (
              <TableList
                list={novedades}
                enableSearchbar={true}
                extraColumnButtons={
                  currentSeccion === "Pendientes"
                    ? [
                        {
                          action: (novedad: NovedadBitacora) => {
                            procesarNovedad(JSON.parse(novedad.datos_evento));
                          },
                          buttonLabel: "Procesar",
                          columnTitle: "Procesar manualmente",
                          theme: "blue",
                        },
                        {
                          action: (novedad: NovedadBitacora) => {
                            descartarNovedad(JSON.parse(novedad.datos_evento));
                          },
                          buttonLabel: "Descartar",
                          columnTitle: "Descartar novedad",
                          theme: "red",
                        },
                      ]
                    : [
                        {
                          action: (novedad: NovedadBitacora) => {
                            borrarNovedad(JSON.parse(novedad.datos_evento));
                          },
                          buttonLabel: "Eliminar",
                          columnTitle: "Eliminar",
                          theme: "red",
                        },
                        {
                          action: (novedad: NovedadBitacora) => {
                            actualizarNovedadAPendiente(novedad.id);
                          },
                          buttonLabel: `Actualizar a "pendiente"`,
                          columnTitle: `Actualizar estado en Oracle`,
                          theme: "blue",
                          disabled: (novedad: any) => {
                            return novedad.estado === "PROCESADO";
                          },
                        },
                      ]
                }
              />
            ) : (
              <AlertInfo label="No se encontraron novedades en D1" />
            )}
          </div>
          <div className="h-32 w-auto rounded-xl">
            <Pagination
              className="w-auto rounded-xl px-5"
              onPageChange={(newPage) => getNewNovedades(newPage)}
              totalCount={totalRows}
              currentPage={currentPage}
              pageSize={pageSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Novedades;
