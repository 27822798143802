import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardAPI } from "../../../../Api/dashboardAPI";
import { DashboardInitState } from "../../Slices/dashboardSlice";
import { toastError, ToastMessage } from "../../../../utils/toast";

export const getDetalleDeCurso = createAsyncThunk(
  "dashboard/getDetalleDeCurso",
  async (idCurso: number, thunkAPI) => {
    try {
      console.log("getDetalleDeCupo...");
      const response = await dashboardAPI.obtenerDetalleCurso(idCurso);
      console.log("response :>> ", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getDetalleDeCursoBuilder = (
  builder: ActionReducerMapBuilder<DashboardInitState>
) => {
  builder.addCase(getDetalleDeCurso.pending, (state, action) => {
    state.loadingPageScreen = true;
  });
  builder.addCase(getDetalleDeCurso.fulfilled, (state, action) => {
    console.log("getDetalleDeCupo fulfilled :>> ", action);
    state.loadingPageScreen = false;
    state.detalleCurso = action.payload;
  });
  builder.addCase(getDetalleDeCurso.rejected, (state, action) => {
    console.log("getDetalleDeCupo rejected :>> ", action);
    toastError(ToastMessage.error);
    state.loadingPageScreen = false;
  });
};
