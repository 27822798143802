import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardAPI } from "../../../../Api/dashboardAPI";
import { ToastMessage, toastError } from "../../../../utils/toast";
import { AlumnosInitState } from "../../Slices/alumnosSlice";
import { RootState } from "../../../../app/store";
import { defaultPaginationConfig } from "../../../../components/reusable/Pagination/Pagination";

export const getAlumnosRegistrados = createAsyncThunk<
  any,
  undefined,
  { state: RootState }
>("dashboard/getAlumnosRegistradosPag", async (params, thunkAPI) => {
  try {
    const state = await thunkAPI.getState();
    const { filtroText, carrera, estadoAlumnoId, conectado } =
      state.alumnos.buscadorAlumnosRegistrados;
    const offset =
      (state.alumnos.registradosCurrentPage - 1) *
      defaultPaginationConfig.limit;

    const response = await dashboardAPI.obtenerAlumnosRegistradosPaginado(
      defaultPaginationConfig.limit,
      offset,
      carrera,
      filtroText,
      estadoAlumnoId,
      conectado
    );
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getAlumnosRegistradosBuilder = (
  builder: ActionReducerMapBuilder<AlumnosInitState>
) => {
  builder.addCase(getAlumnosRegistrados.pending, (state, action) => {
    state.loading = true;
  });
  builder.addCase(getAlumnosRegistrados.fulfilled, (state, action) => {
    console.log("getAlumnosRegistrados fulfilled :>> ", action);
    state.loading = false;
    state.alumnosRegistrados = action.payload;
  });
  builder.addCase(getAlumnosRegistrados.rejected, (state, action) => {
    console.log("inicializarAlumno rejected :>> ", action);
    // toastError(ToastMessage.error);
    state.loading = false;
  });
};
