import { QuotaLevels } from "../enums/reservation.enum";

export const normalizeText = (str: string) => {
    return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const getQuotaColor = (quotaAvailability: number) => {
    switch (true) {
        case quotaAvailability === QuotaLevels.HIGH:
            return 'green'
        case quotaAvailability === QuotaLevels.MID:
            return 'orange'
        case quotaAvailability === QuotaLevels.LOW:
            return 'red'
        case quotaAvailability === QuotaLevels.NONE:
            return 'gray'
        default:
            return 'gray'
    }
}

export function copyTextToClipboard(text: string){
  navigator.clipboard.writeText(text)
};

export const parseHours = (time: number) => {
  const hour = String(time).split("");
  const minutes = hour.splice(-2).join("");
  return `${hour.join("")}:${minutes}`;
};