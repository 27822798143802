import React, { useEffect, useState } from "react";
import Button from "../../../components/reusable/Button/Button";
import TableList from "../../../components/TableList/TableList";
import { useAppDispatch } from "../../../app/store";
import PageLoader from "../../../components/reusable/PageLoader/PageLoader";
import { useSelector } from "react-redux";
import { dashboardAPI } from "../../../Api/dashboardAPI";
import { Alumno } from "../../../types/alumnos";
import { getDetalleDeAlumno } from "../../../features/Dashboard/AsyncThunks/alumnos/getDetalleDeAlumno";
import { getAlumnosRegistrados } from "../../../features/Dashboard/AsyncThunks/alumnos/getAlumnosRegistrados";
import ModalConfirmation from "../../../components/reusable/ModalConfirmation/ModalConfirmation";
import { toastError, toastSuccess } from "../../../utils/toast";
import Pagination from "../../../components/reusable/Pagination/Pagination";
import SlRadio from "@shoelace-style/shoelace/dist/react/radio";
import {
  selectAlumnosRegistrados,
  selectRegistradosCurrentPage,
  setRegistradosCurrentPage,
} from "../../../features/Dashboard/Slices/alumnosSlice";
import BuscadorAlumnosRegistrados from "../../../components/BuscadorAlumnosRegistrados";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlDrawer from "@shoelace-style/shoelace/dist/react/drawer";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import CopyTooltip from "../../../components/CopyTooltip/CopyTooltip";
import {
  selectGlobalFlags,
  setAlumnoDetailLoading,
  setDrawerInicializar,
} from "../../../features/Dashboard/Slices/globalFlagsSlice";

function AlumnosRegistrados() {
  const [loading, setLoading] = useState(false);
  const alumnosRegistrados = useSelector(selectAlumnosRegistrados);
  const registradosCurrentPage = useSelector(selectRegistradosCurrentPage);
  const [alumnoSelected, setAlumnoSelected] = useState<Alumno | undefined>();
  const { alumnoDetailLoading } = useSelector(selectGlobalFlags);
  const confirmationModalInitialState = {
    show: false,
    title: "",
    dialog: "",
    onConfirm: () => {},
  };
  const dispatch = useAppDispatch();
  const [confirmationModal, setConfirmationModal] = useState(
    confirmationModalInitialState
  );
  const clearConfirmationModal = () => {
    setConfirmationModal(confirmationModalInitialState);
  };

  /* -------------------------------------------------------------------------- */
  /*                               LÓGICA PAGINADO                              */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getAlumnosRegistradosPaginadoInicial();
  }, []);

  const getAlumnosRegistradosPaginadoInicial = async () => {
    await dispatch(setRegistradosCurrentPage(1));
    await getAlumnosRegistradosPaginado();
  };

  const getAlumnosRegistradosPaginado = async () => {
    setLoading(true);
    await dispatch(getAlumnosRegistrados());
    setLoading(false);
  };

  const handleChangePage = async (newPage: number) => {
    await dispatch(setRegistradosCurrentPage(newPage));
    await getAlumnosRegistradosPaginado();
  };
  /* -------------------------------------------------------------------------- */
  /*                               ACCIONES ALUMNO                              */
  /* -------------------------------------------------------------------------- */
  const resetearAlumno = async () => {
    setLoading(true);
    clearConfirmationModal();
    try {
      if (alumnoSelected) {
        const { id_persona } = alumnoSelected;
        const res = await dashboardAPI.resetearAlumno(id_persona);
        toastSuccess(JSON.stringify(res));
        getAlumnosRegistradosPaginadoInicial();
      } else {
        throw new Error("No se encuentra id_persona");
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const borrarAlumno = async () => {
    setLoading(true);
    clearConfirmationModal();
    try {
      if (alumnoSelected) {
        const { id_persona } = alumnoSelected;
        const res = await dashboardAPI.eliminarAlumno(id_persona);
        toastSuccess(JSON.stringify(res));
        getAlumnosRegistradosPaginadoInicial();
      } else {
        throw new Error("No se encuentra id_persona");
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const verDetalleDeAlumno = async () => {
    if (alumnoSelected) {
      const { id_alumno_carrera } = alumnoSelected;
      await dispatch(getDetalleDeAlumno(id_alumno_carrera));
    }
  };
  const verDetalleDeAlumnoById = async (id: number) => {
    if (id) {
      dispatch(setAlumnoDetailLoading(id));
      const res = await dispatch(getDetalleDeAlumno(id));
      if (res.payload) {
        dispatch(setAlumnoDetailLoading(null));
      }
    }
  };

  const actualizarOfertas = async () => {
    setLoading(true);
    try {
      if (alumnoSelected) {
        const res = await dashboardAPI.actualizarOferta(
          alumnoSelected.id_alumno_carrera
        );
        toastSuccess(JSON.stringify(res));
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const actualizarInscripciones = async () => {
    setLoading(true);
    try {
      if (alumnoSelected) {
        const res = await dashboardAPI.actualizarInscripciones(
          alumnoSelected.id_alumno_carrera
        );
        toastSuccess(JSON.stringify(res));
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const invalidarSemaforo = async () => {
    setLoading(true);
    try {
      if (alumnoSelected) {
        const res = await dashboardAPI.invalidarSemaforo(
          alumnoSelected.id_persona
        );
        toastSuccess(JSON.stringify(res));
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    }
    setLoading(false);
  };
  
  const enviarLogout = async () => {
    setLoading(true);
    clearConfirmationModal();
    try {
      if (alumnoSelected) {
        const res = await dashboardAPI.logoutIndividual(
          alumnoSelected.id_persona
        );
        toastSuccess(JSON.stringify(res));
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const vencerOferta = async () => {
    setLoading(true);
    clearConfirmationModal();
    try {
      if (alumnoSelected) {
        const res = await dashboardAPI.vencerOferta(
          alumnoSelected.id_alumno_carrera
        );
        toastSuccess(JSON.stringify(res));
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const invalidarOferta = async () => {
    setLoading(true);
    clearConfirmationModal();
    try {
      if (alumnoSelected) {
        const res = await dashboardAPI.invalidarOferta(
          alumnoSelected.id_alumno_carrera
        );
        toastSuccess(JSON.stringify(res));
      }
    } catch (error) {
      toastError("Ocurrió un error inesperado...");
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const resetearAlumnosRegistrados = async () => {
    setLoading(true);
    clearConfirmationModal();
    try {
      const res = await dashboardAPI.resetearAlumnosRegistrados();
      toastSuccess(JSON.stringify(res));
    } catch (error) {
      toastError();
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const invalidarSemaforoGlobal = async () => {
    setLoading(true);
    clearConfirmationModal();
    try {
      const res = await dashboardAPI.invalidarSemaforoGlobal();
      toastSuccess(JSON.stringify(res));
    } catch (error) {
      toastError();
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const vencerOfertaGlobal = async () => {
    setLoading(true);
    clearConfirmationModal();
    try {
      const res = await dashboardAPI.vencerOfertaGlobal();
      toastSuccess(JSON.stringify(res));
    } catch (error) {
      toastError();
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const invalidarOfertaGlobal = async () => {
    setLoading(true);
    clearConfirmationModal();
    try {
      const res = await dashboardAPI.invalidarOfertaGlobal();
      toastSuccess(JSON.stringify(res));
    } catch (error) {
      toastError();
      console.log("error :>> ", error);
    }
    setLoading(false);
  };
  /* -------------------------------------------------------------------------- */
  /*                                   RENDER                                   */
  /* -------------------------------------------------------------------------- */
  const alumnoAccionesBotones = [
    {
      action: () => resetearAlumno(),
      label: "Resetear",
      theme: "blue",
      confirmationTitle: `¿Desea resetear al alumno ${alumnoSelected?.alumno}?`,
      confirmationDialog: "",
      variant: "default",
      icon: "recycle",
    },
    {
      action: () => borrarAlumno(),
      label: "Borrar",
      theme: "red",
      confirmationTitle: `¿Desea borrar al alumno ${alumnoSelected?.alumno}?`,
      confirmationDialog: "",
      variant: "danger",
      icon: "trash3",
    },
    {
      action: () => verDetalleDeAlumno(),
      label: "Detalle",
      theme: "yellow",
      variant: "success",
      icon: "journal-text",
    },
    {
      action: () => actualizarOfertas(),
      label: "Actualizar ofertas",
      theme: "green",
      variant: "primary",
      icon: "arrow-clockwise",
    },
    {
      action: () => actualizarInscripciones(),
      label: "Actualizar inscripciones",
      theme: "blue",
      variant: "primary",
      icon: "arrow-clockwise",
    },
    {
      action: () => invalidarSemaforo(),
      label: "Invalidar semáforo",
      theme: "red",
      variant: "warning",
      icon: "sign-stop-lights",
    },
    {
      action: () => invalidarOferta(),
      label: "Invalidar oferta",
      theme: "yellow",
      confirmationTitle: `¿Desea inválidar la oferta al alumno ${alumnoSelected?.alumno}?`,
      confirmationDialog: "",
      variant: "warning",
      icon: "card-list",
    },
    {
      action: () => vencerOferta(),
      label: "Vencer oferta",
      theme: "red",
      confirmationTitle: `¿Desea vencer la oferta al alumno ${alumnoSelected?.alumno}?`,
      confirmationDialog: "",
      variant: "danger",
      icon: "sign-stop",
    },
    {
      action: () => enviarLogout(),
      label: "Enviar logout",
      theme: "blue",
      confirmationTitle: `¿Desea desloguear al alumno ${alumnoSelected?.alumno}?`,
      confirmationDialog: "",
      variant: "default",
      icon: "door-closed",
    },
  ];

  return (
    <>
      {loading && <PageLoader />}
      <ModalConfirmation
        show={confirmationModal.show}
        onClose={clearConfirmationModal}
        onConfirm={confirmationModal.onConfirm}
        title={confirmationModal.title}
        message={confirmationModal.dialog}
      />

      <div className="bg-white p-6 rounded-lg border-2 border-cardBorder col-span-1 flex flex-col">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Total alumnos / Online
        </h4>
        <p className="text-xl text-right font-semibold">
          {alumnosRegistrados.totalCount}/{alumnosRegistrados.onlineCount}
        </p>
      </div>

      <div className="bg-white p-6 rounded-lg border-2 border-cardBorder col-span-4 flex flex-col">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Acciones globales
        </h4>
        <div className="flex flex-wrap gap-2">
          <button
            className="text-textPrimary font-bold hover:underline border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
            onClick={getAlumnosRegistradosPaginadoInicial}
          >
            Actualizar listado
          </button>

          <button
            className="text-textPrimary font-bold hover:underline border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
            onClick={() => {
              setConfirmationModal({
                show: true,
                onConfirm: resetearAlumnosRegistrados,
                title: "¿Desea resetear todos los alumnos?",
                dialog: "Todos los alumnos se verán afectados",
              });
            }}
          >
            Resetear todos
          </button>

          <button
            className="text-textPrimary font-bold hover:underline border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
            onClick={() => {
              setConfirmationModal({
                show: true,
                onConfirm: invalidarSemaforoGlobal,
                title: "Desea invalidar semaforo global?",
                dialog: "",
              });
            }}
          >
            Invalidar semaforo global
          </button>

          <button
            className="text-textPrimary font-bold hover:underline border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
            onClick={() => {
              setConfirmationModal({
                show: true,
                onConfirm: invalidarOfertaGlobal,
                title: "¿Desea invalidar oferta global?",
                dialog: "",
              });
            }}
          >
            Invalidar oferta global
          </button>

          <button
            className="text-textPrimary font-bold hover:underline border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
            onClick={() => {
              setConfirmationModal({
                show: true,
                onConfirm: vencerOfertaGlobal,
                title: "¿Desea vencer oferta global?",
                dialog: "",
              });
            }}
          >
            Vencer oferta global
          </button>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg border-2 border-cardBorder col-span-5 flex flex-col">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Listado
        </h4>
        <div className="flex gap-10">
          <BuscadorAlumnosRegistrados />
          <button
            className="text-textPrimary font-bold hover:underline border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
            onClick={() => dispatch(setDrawerInicializar(true))}
          >
            Inicializar alumnos
          </button>
        </div>
        <div className="h-auto mt-4">
          <div>
            <TableList
              list={alumnosRegistrados.alumnos}
              customColumns={[
                {
                  columnTitle: "Select",
                  boxContent: (alumno) => {
                    return (
                      <div className="flex w-10 justify-center">
                        <SlRadio
                          className="pl-2"
                          value="1"
                          checked={
                            alumnoSelected?.id_persona === alumno?.id_persona
                          }
                          onClick={() => setAlumnoSelected(alumno)}
                        />
                      </div>
                    );
                  },
                },
                {
                  columnTitle: "Details",
                  boxContent: (alumno) => {
                    return (
                      <div className="flex w-10 justify-center">
                        <SlIcon
                          className={`text-[#a0a0a0] hover:text-[#6e6e6e] hover:cursor-pointer ${
                            alumnoDetailLoading === alumno.id_alumno_carrera
                              ? "animate-ping"
                              : ""
                          }`}
                          name="eye"
                          style={{ fontSize: "18px" }}
                          onClick={() =>
                            verDetalleDeAlumnoById(alumno?.id_alumno_carrera)
                          }
                        ></SlIcon>
                      </div>
                    );
                  },
                },
              ]}
            />
          </div>

          {/* <table className="border-collapse table-auto w-full">
            <thead>
              <tr>
                <th>Id persona</th>
                <th>Nombre</th>
                <th>Email</th>
                <th>Carrera</th>
                <th>Estado</th>
                <th>Oferta</th>
                <th>Inscripciones</th>
              </tr>
            </thead>
            <tbody>
              {alumnosRegistrados.alumnos &&
                [...alumnosRegistrados.alumnos]
                  .sort((a: Alumno, b: Alumno) =>
                    a.alumno.localeCompare(b.alumno)
                  )
                  .map((alumno) => (
                    <tr className="odd:bg-white even:bg-grey">
                      <td className="">
                        {alumno.id_persona}
                      </td>
                      <td className="flex items-center gap-2">
                        <p
                          className="hover:underline hover:cursor-pointer hover:font-semibold"
                          onClick={() => setAlumnoSelected(alumno)}
                        >
                          {alumno.alumno}
                        </p>
                        <div
                          className={`w-2 h-2 rounded-full ${
                            alumno.online === "ONLINE"
                              ? "bg-[green] animate-pulse"
                              : "bg-grey"
                          }`}
                        ></div>
                      </td>

                      <td className="">{alumno.mails}</td>
                      <td className="">{alumno.carrera}</td>
                      <td className="text-[10px]">{alumno.estado}</td>
                      <td className="">{alumno.oferta}</td>
                      <td className="">{alumno.inscripciones}</td>
                    </tr>
                  ))}
            </tbody>
          </table> */}

          <Pagination
            totalCount={alumnosRegistrados.totalCount}
            onPageChange={handleChangePage}
            currentPage={registradosCurrentPage}
          />
        </div>
      </div>

      <SlDrawer
        label="Alumno seleccionado"
        open={alumnoSelected ? true : false}
        onSlAfterHide={() => setAlumnoSelected(undefined)}
      >
        {alumnoSelected && (
          <div>
            <div className="flex flex-col items-center justify-center py-4 bg-[#eee] rounded-lg mb-4">
              <SlIcon
                name="person-video2"
                style={{ fontSize: "32px" }}
              ></SlIcon>
              <h3 className="text-center text-lg uppercase">
                {alumnoSelected?.alumno}
              </h3>
              <div className="text-xs">
                <CopyTooltip textToCopy={alumnoSelected.mails}>
                  <p className="font-semibold hover:underline">
                    {alumnoSelected.mails.trim()}
                  </p>
                </CopyTooltip>

                <p className="text-center text-nowrap">
                  Id alumno carrera:{" "}
                  <CopyTooltip
                    textToCopy={String(alumnoSelected?.id_alumno_carrera)}
                  >
                    <span className="font-semibold hover:underline">
                      {alumnoSelected?.id_alumno_carrera}
                    </span>
                  </CopyTooltip>
                </p>
                <p className="text-center">
                  Id persona:{" "}
                  <CopyTooltip textToCopy={String(alumnoSelected?.id_persona)}>
                    <span className="font-semibold hover:underline hover:cursor-default active:cursor-copy">
                      {alumnoSelected?.id_persona}
                    </span>
                  </CopyTooltip>
                </p>
              </div>
            </div>

            <div className="space-y-4">
              {alumnoAccionesBotones.map(
                ({
                  action,
                  label,
                  confirmationDialog,
                  confirmationTitle,
                  variant = "default",
                  icon = "",
                }: any) => (
                  <SlButton
                    outline
                    variant={variant}
                    size="medium"
                    className="w-full"
                    onClick={
                      confirmationTitle
                        ? () => {
                            setConfirmationModal({
                              show: true,
                              onConfirm: action,
                              title: confirmationTitle,
                              dialog: confirmationDialog,
                            });
                          }
                        : action
                    }
                    // label={label}
                    // theme={theme}
                  >
                    <SlIcon
                      slot="prefix"
                      name={icon}
                      style={{ fontSize: "20px" }}
                    ></SlIcon>
                    {label}
                  </SlButton>
                )
              )}
            </div>
          </div>
        )}
      </SlDrawer>
    </>
  );
}

export default AlumnosRegistrados;
