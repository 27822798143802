import React, { useEffect, useState } from "react";
import Selector from "./reusable/Selector/Selector";
import InputSearch from "./reusable/InputSearch/InputSearch";
import { useSelector } from "react-redux";
import {
  selectAlumnosCarreras,
  selectBuscadorAlumnosRegistrados,
  setCarreraSearch,
  setConectadoSearch,
  setEstadoAlumnoSearch,
  setRegistradosCurrentPage,
  setTextSearch,
} from "../features/Dashboard/Slices/alumnosSlice";
import { useAppDispatch } from "../app/store";
import { getAlumnosRegistrados } from "../features/Dashboard/AsyncThunks/alumnos/getAlumnosRegistrados";
import { getCarreras } from "../features/Dashboard/AsyncThunks/alumnos/getCarreras";
import { setLoadingPageScreen } from "../features/Dashboard/Slices/dashboardSlice";

function BuscadorAlumnosRegistrados() {
  const carrerasList = useSelector(selectAlumnosCarreras);
  const buscador = useSelector(selectBuscadorAlumnosRegistrados);
  const dispatch = useAppDispatch();
  const setLoading = (newState: boolean) => {
    dispatch(setLoadingPageScreen(newState));
  };
  const [carreraSelected, setCarreraSelected] = useState("");
  const estadoAlumnosListSelector = [
    { text: "REGISTRADO", value: 0 },
    { text: "PENDIENTE", value: 1 },
    { text: "INICIALIZADO", value: 2 },
    { text: "ACCESO BLOQUEADO", value: 3 },
    { text: "OFERTA INVÁLIDA", value: 4 },
    { text: "OFERTA VENCIDA", value: 5 },
    { text: "GENERANDO OFERTA VENCIDA", value: 6 },
    { text: "GENERANDO OFERTA INVALIDA", value: 7 },
  ];

  useEffect(() => {
    getCarrerasList();
  }, []);

  const getCarrerasList = async () => {
    await dispatch(getCarreras());
  };

  const buscarAlumnos = async () => {
    setLoading(true);
    await dispatch(setRegistradosCurrentPage(1));
    await dispatch(getAlumnosRegistrados());
    setLoading(false);
  };

  const setSearchAlumnosFilter = async (text: string) => {
    await dispatch(setTextSearch(text));
  };

  const saveCarrera = async (text: string) => {
    await dispatch(setCarreraSearch(text));
  };

  const saveEstadoAlumno = async (text: string) => {
    await dispatch(setEstadoAlumnoSearch(text));
  };

  const saveConectadoSeach = async (text: string) => {
    await dispatch(setConectadoSearch(text));
  };

  useEffect(() => {
    // Al seleccionar un ipo, se actualiza el selector de busqueda de alumnos registrados
    setCarreraSelected(buscador.carrera?.replace(/ /g, "_") || "");
  }, [buscador.carrera]);

  return (
    <div className="flex w-2/3 gap-4 items-end self-start">
      <Selector
        options={carrerasList.map((carrera) => {
          return { text: carrera, value: carrera.replace(/ /g, "_") };
        })}
        onSelect={saveCarrera}
        placeholder="Seleccionar una carrera"
        className={"w-1/3 text-sm"}
        label="Carrera"
        unselectedText="Sin filtrar"
        defaultValue={carreraSelected}
      />
      <Selector
        options={estadoAlumnosListSelector.map((estado) => {
          return { text: estado.text, value: estado.value.toString() };
        })}
        onSelect={saveEstadoAlumno}
        defaultValue={buscador.estadoAlumnoId}
        placeholder="Estado de alumno"
        label="Estado de alumno"
        className={"w-1/3 text-sm"}
        unselectedText="Sin filtrar"
      />
      <Selector
        options={[
          { text: "ONLINE", value: "ONLINE" },
          { text: "OFFLINE", value: "OFFLINE" },
        ]}
        onSelect={saveConectadoSeach}
        placeholder="Conectado"
        label="Conectado"
        className={"w-auto text-sm"}
        unselectedText="Sin filtrar"
      />
      <div className="flex w-2/3">
        {/* <p>Buscar por emails, idAlumnoPrograma, idPersona, estado y nombre de alumno</p> */}
        <InputSearch
          onChange={setSearchAlumnosFilter}
          placeholder="Buscar alumnos"
          onConfirmSearch={buscarAlumnos}
          value={buscador.filtroText}
          className="w-full"
          size="small"
        />
        <button
          className="w-32 text-white bg-primary rounded-r-md z-[1] ml-[-3px]"
          onClick={buscarAlumnos}
        >
          Buscar
        </button>
      </div>
    </div>
  );
}

export default BuscadorAlumnosRegistrados;
