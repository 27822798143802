import { useEffect, useState } from "react";
import "./Cupos.css";
import { useAppDispatch } from "../../app/store";
import {
  selectCuadraturaCupos,
  selectCuposAnalizados,
  selectCuposTotales,
  selectDetalleCupo,
} from "../../features/Dashboard/Slices/dashboardSlice";
import Input from "../../components/reusable/Input/Input";
import Button from "../../components/reusable/Button/Button";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import { useSelector } from "react-redux";
import TableList from "../../components/TableList/TableList";
import TableListInverted from "../../components/TableListInverted/TableListInverted";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { toastInfo, toastSuccess } from "../../utils/toast";
import { getDetalleDeCupo } from "../../features/Dashboard/AsyncThunks/cupos/getDetalleDeCupo";
import {
  getDetalleCuadratura,
  procesarCuadratura,
} from "../../features/Dashboard/AsyncThunks/cupos/getDetalleCuadratura";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

function Cupos() {
  const cuposTotales = useSelector(selectCuposTotales);
  const cuposAnalizados = useSelector(selectCuposAnalizados);
  const [loading, setLoading] = useState(false);
  const [idCupo, setIdCupo] = useState<number | null>(null);
  const detalleCupo = useSelector(selectDetalleCupo);
  const cuadraturaCupo = useSelector(selectCuadraturaCupos);
  const dispatch = useAppDispatch();

  const [btnDescription, setBtnDescription] = useState<number|null>(null)

  const updateCupo = async () => {
    if (idCupo) {
      setLoading(true);
      await dashboardAPI.actualizarCupo(idCupo);
      //await dispatch(getDetalleDeCupo(idCupo));
      toastSuccess("En unos instantes se actualizará la información");
      setLoading(false);
    } else {
      toastInfo("Ingrese idCupo");
    }
  };

  const syncCuposDescuadrados = async () => {
    setLoading(true);
    await dashboardAPI.syncCuposDescuadrados();
    toastSuccess("En unos instantes se actualizará la información");
    setLoading(false);
  };

  const getCuadratura = async () => {
    setLoading(true);
    await dispatch(getDetalleCuadratura());
    setLoading(false);
  };

  const calcularCuadratura = async () => {
    setLoading(true);
    await dispatch(procesarCuadratura());
    setLoading(false);
  };

  const getDetalleCupo = async () => {
    if (idCupo) {
      setLoading(true);
      await dispatch(getDetalleDeCupo(idCupo));
      setLoading(false);
    } else {
      toastInfo("Ingrese idCupo");
    }
  };
  useEffect(() => {
    console.log("detalleCupo :>> ", detalleCupo);
  }, [detalleCupo]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    getDetalleCupo();
  };

  const accionesDeCupos = [
    {
      label: "Actualizar desde BO",
      action: updateCupo,
      description: "Definir funcionamiento de la accion",
    },
    {
      label: " Consultar Cuadratura",
      action: getCuadratura,
      description: "Definir funcionamiento de la accion",
    },
    {
      label: " Calcular Cuadratura",
      action: calcularCuadratura,
      description: "Definir funcionamiento de la accion",
    },
    {
      label: "Sincronizar Cupos Descuadrados",
      action: syncCuposDescuadrados,
      description: "Definir funcionamiento de la accion",
    },
  ];

  return (
    <div className="p-6 grid grid-cols-4 gap-6">
      <div className="bg-white p-6 rounded-lg border-2 border-cardBorder col-span-3 flex flex-col">
        {/* {loading && <PageLoader />} */}
        <div className="flex items-center justify-between mb-2">
          <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
            Cuadratura de cupos
          </h4>
          <form className="flex" onSubmit={handleSubmit}>
            <Input
              size="small"
              onChange={(text) => setIdCupo(Number(text))}
              type="number"
              placeholder="Ingrese idCupo (número)"
              className="rounded-r-none"
            />
            <button
              type="submit"
              className="ml-[-3px] z-[1] w-28 text-white bg-primary rounded-r-md"
            >
              Buscar
            </button>
          </form>
        </div>
        {detalleCupo && (
          <div className="py-4">
            {/* <h4 className="uppercase font-semibold text-primary border-b-[1px] border-primary pb-1 mb-4 mt-6">
              Detalle cupo
            </h4> */}
            <div className="grid grid-cols-3 gap-3">
              {Object.keys(detalleCupo).map((key: string) => {
                return (
                  <div
                    className={`bg-[white] border-[1px] border-cardBorder pt-1 px-2 rounded-md relative ${
                      key === "detalle" ? "col-span-2" : ""
                    }`}
                  >
                    <p className="flex-grow text-[10px] text-secondary absolute top-[-8px] px-1 bg-white">
                      {key}
                    </p>
                    <p className="text-center  font-semibold">
                      {/* @ts-ignore */}
                      {detalleCupo[key]}
                    </p>
                  </div>
                );
              })}
            </div>
            {/* <TableListInverted list={[detalleCupo]} /> */}
          </div>
        )}

        <div className="bg-white rounded-md p-4">
          <div className="flex">
            <h4 className="">Cuadratura de cupos</h4>
            <p className="flex-grow text-center font-semibold">
              Total: {cuposTotales} / Analizados: {cuposAnalizados}
            </p>
          </div>
          <hr className="text-secondary my-2" />
          {cuadraturaCupo.length > 0 ? (
            <TableList list={cuadraturaCupo} />
          ) : (
            <p className="text-secondary">
              No se encuentran datos de cuatratura
            </p>
          )}
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg border-2 border-cardBorder col-span-1 self-start">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Acciones
        </h4>
        <div className="space-y-2">
          {accionesDeCupos.map((btn, index) => (
            <button
              className="text-textPrimary font-bold w-full hover:underline border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
              onClick={btn.action}
              onMouseEnter={()=>setBtnDescription(index)}
              onMouseLeave={()=>setBtnDescription(null)}
            >
              {btn.label}
            </button>
          ))}
        </div>
        <p className="text-textSecondary mt-3">
          <SlIcon name="info-circle" className="pr-2 text-[14px]"></SlIcon>
          {btnDescription === null ? (
            <>Descripción de la acción al pasar sobre el botón.</>
          ) : (
            <>
              <span className="font-semibold uppercase">
                {accionesDeCupos[btnDescription].label}:
              </span>
              {accionesDeCupos[btnDescription].description}
            </>
          )}
        </p>
      </div>
    </div>
  );
}

export default Cupos;
