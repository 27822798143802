import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/store";
import { selectParametros } from "../../features/Dashboard/Slices/dashboardSlice";
import { useSelector } from "react-redux";
import Input from "../../components/reusable/Input/Input";
import Button from "../../components/reusable/Button/Button";
import PageLoader from "../../components/reusable/PageLoader/PageLoader";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { ToastMessage, toastError, toastSuccess } from "../../utils/toast";
import ModalConfirmation from "../../components/reusable/ModalConfirmation/ModalConfirmation";
import { getParametros } from "../../features/Dashboard/AsyncThunks/parametros/getParametros";
import SlDrawer from "@shoelace-style/shoelace/dist/react/drawer";
import SlTextarea from "@shoelace-style/shoelace/dist/react/textarea";
import SlSwitch from "@shoelace-style/shoelace/dist/react/switch";
import SlIconButton from "@shoelace-style/shoelace/dist/react/icon-button";
import SlTooltip from "@shoelace-style/shoelace/dist/react/tooltip";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

export enum Parametro {
  PROCESAR_NOVEDADES_ORACLE_MANUALMENTE = "PROCESAR_NOVEDADES_ORACLE_MANUALMENTE",
  DESCARTAR_NOVEDADES_ORACLE = "DESCARTAR_NOVEDADES_ORACLE",
}

function Parametros() {
  const dispatch = useAppDispatch();
  const parametros = useSelector(selectParametros);

  const [loading, setLoading] = useState(false);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [paramToEdit, setParamToEdit] = useState<string | null>(null);
  const [newParamValue, setNewParamValue] = useState<string>("");

  // Obtener parámetros al montar
  useEffect(() => {
    if (!parametros.length) obtenerParametros();
  }, []);

  const obtenerParametros = async () => {
    setLoading(true);
    await dispatch(getParametros());
    setLoading(false);
  };

  const updateParametro = async () => {
    if (!paramToEdit) return;

    setLoading(true);
    try {
      await dashboardAPI.updateParametro(paramToEdit, newParamValue);
      toastSuccess(`Parámetro ${paramToEdit} actualizado correctamente`);
      setParamToEdit(null);
      setNewParamValue("");
      obtenerParametros(); // Refrescar parámetros
    } catch (error) {
      toastError(ToastMessage.error);
    } finally {
      setShowModalConfirmation(false);
      setLoading(false);
    }
  };

  const handleEdit = (clave: string, valor: string) => {
    setParamToEdit(clave);
    setNewParamValue(valor);
  };

  const handleSwitchEdit = (clave: string, valor: string) => {
    const newValue = valor === "true" ? "false" : "true";
    setParamToEdit(clave);
    setNewParamValue(newValue);
    updateParametro();
  };

  const currentParam = parametros.find((param) => param.clave === paramToEdit);

  return (
    <div className="p-6 grid grid-cols-4 gap-6">
      {loading && <PageLoader />}
      <ModalConfirmation
        show={showModalConfirmation}
        message={`Seguro que quiere actualizar el parámetro ${paramToEdit}?`}
        onClose={() => setShowModalConfirmation(false)}
        onConfirm={updateParametro}
        title="Actualizar parámetro"
      />

      <div className="bg-white p-6 rounded-lg col-span-3 border-2 border-cardBorder overflow-x-hidden">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Configuración parámetros
        </h4>

        <div className="mt-2">
          <div className="grid grid-cols-2 gap-2">
            {!loading &&
              parametros.map(({ clave, valor, descripcion }) => {
                const editMode = paramToEdit === clave;
                return (
                  <div key={clave} className=" flex items-center bg-white">
                    <div className="flex-grow">
                      <SlTooltip content={clave + ">> " + descripcion}>
                        <p className="font-semibold">{clave}</p>
                      </SlTooltip>
                    </div>
                    {valor === "true" || valor === "false" ? (
                      <SlSwitch
                        size="medium"
                        checked={valor === "true" ? true : false}
                        onClick={() => handleSwitchEdit(clave, valor)}
                      ></SlSwitch>
                    ) : (
                      <div className="px-1 w-1/3 flex items-center">
                        <p className="flex-grow line-clamp-1 text-secondary text-right">
                          {valor}
                        </p>
                        <SlTooltip content="Editar">
                          <SlIconButton
                            className="text-secondary"
                            onClick={() =>
                              paramToEdit === clave
                                ? setShowModalConfirmation(true)
                                : handleEdit(clave, valor)
                            }
                            name="pencil-square"
                            label="Editarr"
                          />
                        </SlTooltip>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg col-span-1 border-2 border-cardBorder self-start">
        <h4 className="font-semibold text-textSecondary mb-3 text-[16px]">
          Acciones
        </h4>
        <button
          className="text-textPrimary font-bold flex items-center gap-2 hover:underline  border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
          onClick={obtenerParametros}
        >
          <SlIcon name="arrow-clockwise"></SlIcon>
          Refrescar parámetros
        </button>
      </div>

      <SlDrawer
        label="Editar parámetro"
        open={
          !!paramToEdit &&
          !(currentParam?.valor === "true" || currentParam?.valor === "true")
        }
        onSlAfterHide={() => {
          setParamToEdit(null);
          setNewParamValue("");
        }}
      >
        {currentParam && (
          <div className="space-y-2">
            <p className="font-semibold">{currentParam.clave}</p>
            <p>{currentParam.descripcion}</p>
            <SlTextarea
              value={newParamValue}
              onSlInput={(e: any) => setNewParamValue(e.target.value)}
              className="w-full"
            />
            <Button
              iconName="check-circle"
              className="mx-3"
              theme="primary"
              label="Actualizar"
              onClick={() => setShowModalConfirmation(true)}
            />
          </div>
        )}
      </SlDrawer>
    </div>
  );
}

export default Parametros;
