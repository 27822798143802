import React, { useEffect, useState } from "react";
import SlOption from "@shoelace-style/shoelace/dist/react/option";
import SlSelect from "@shoelace-style/shoelace/dist/react/select";
interface Props {
  options: { value: string; text: string }[];
  placeholder?: string;
  unselectedText?: string;
  onSelect: (value: string) => void;
  className?: string;
  label?: string;
  defaultValue?: string;
}

function Selector({
  options,
  placeholder,
  onSelect,
  className,
  label,
  unselectedText,
  defaultValue,
}: Props) {
  const [currentValue, setCurrentValue] = useState(defaultValue || "");

  useEffect(() => {
    setCurrentValue(defaultValue || "");
  }, [defaultValue]);

  const handleValueChange = (text: string) => {
    setCurrentValue(text);
    const textParsed = text.replaceAll("_", " ");
    onSelect(textParsed);
  };

  return (
    <div className={className}>
      <p className="text-sm font-semibold text-textPrimary">{label}</p>
      <SlSelect
        size="small"
        placeholder={placeholder}
        onSlChange={(e: any) => handleValueChange(e?.target?.value)}
        value={currentValue}
      >
        <SlOption value={""}>{unselectedText}</SlOption>
        {options.map(({ text, value }) => (
          <SlOption key={value} value={value}>
            {text}
          </SlOption>
        ))}
      </SlSelect>
    </div>
  );
}

export default Selector;
