import React, { useState } from "react";
import AlumnosRegistrados from "./secciones/AlumnosRegistrados";
import DetalleAlumno from "./secciones/DetalleAlumno";
import AlumnosInicializados from "./secciones/AlumnosInicializados";
import "./Alumnos.css";

function Alumnos() {
  return (
    <div className="p-6 grid grid-cols-5 gap-6">
      <AlumnosRegistrados />
      <AlumnosInicializados />
      <DetalleAlumno />
    </div>
  );
}

export default Alumnos;
