import { useEffect, useState } from "react";
import Button from "../../../components/reusable/Button/Button";
import TableList from "../../../components/TableList/TableList";
import PageLoader from "../../../components/reusable/PageLoader/PageLoader";
import Input from "../../../components/reusable/Input/Input";
import { useAppDispatch } from "../../../app/store";
import { useSelector } from "react-redux";
import { inicializarAlumno } from "../../../features/Dashboard/AsyncThunks/alumnos/inicializarAlumno";
import {
  getAlumnosInicializados,
  postBorrarIPO,
  postBorrarTodosLosAlumnos,
  postInvalidarOfertas,
  postResetearIPO,
  postSincronizarAlumnos,
  postVencerOfertas,
} from "../../../features/Dashboard/AsyncThunks/alumnos/getAlumnosInicializados";
import { selectAlumnosCarreras, selectAlumnosInicializados, setCarreraSearch, setEstadoAlumnoSearch, setRegistradosCurrentPage } from "../../../features/Dashboard/Slices/alumnosSlice";
import { setLoadingPageScreen } from "../../../features/Dashboard/Slices/dashboardSlice";
import ModalConfirmation from "../../../components/reusable/ModalConfirmation/ModalConfirmation";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlDrawer from "@shoelace-style/shoelace/dist/react/drawer";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import {
  selectGlobalFlags,
  setDrawerInicializar,
} from "../../../features/Dashboard/Slices/globalFlagsSlice";
import { getAlumnosRegistrados } from "../../../features/Dashboard/AsyncThunks/alumnos/getAlumnosRegistrados";

function AlumnosInicializados() {
  const alumnosInicializados = useSelector(selectAlumnosInicializados);
  const { drawerInicializar } = useSelector(selectGlobalFlags);
  const [email, setEmail] = useState("");
  const [identificacion, setIdentificacion] = useState("");
  const [programa, setPrograma] = useState("");
  const [orientacion, setOrientacion] = useState("");
  const dispatch = useAppDispatch();
  const confirmationModalInitialState = {
    show: false,
    title: "",
    dialog: "",
    onConfirm: () => {},
  };
  const [confirmationModal, setConfirmationModal] = useState(
    confirmationModalInitialState
  );
  const carrerasList = useSelector(selectAlumnosCarreras);
  
  const clearConfirmationModal = () => {
    setConfirmationModal(confirmationModalInitialState);
  };

  const setLoading = (loaderState: boolean) => {
    dispatch(setLoadingPageScreen(loaderState));
  };

  useEffect(() => {
    ObtenerAlumnosInicializados();
  }, []);

  const handleInicializarAlumno = async () => {
    setLoading(true);
    clearConfirmationModal();
    await dispatch(inicializarAlumno(email));
    setEmail("");
    setLoading(false);
  };

  const ObtenerAlumnosInicializados = async () => {
    setLoading(true);
    try {
      await dispatch(getAlumnosInicializados());
    } catch (error) {
      console.log("error :>> ", error);
    }
    setLoading(false);
  };

  const BorrarTodosLosAlumnos = async () => {
    setLoading(true);
    try {
      await dispatch(postBorrarTodosLosAlumnos());
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const VencerOfertas = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postVencerOfertas({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const BorrarIPO = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postBorrarIPO({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const ResetearIPO = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postResetearIPO({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const InvalidarOfertas = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postInvalidarOfertas({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const SincronizarAlumnos = async () => {
    setLoading(true);
    try {
      const i: number | null =
        identificacion && identificacion.length > 0
          ? Number(identificacion)
          : null;
      const p: number | null =
        programa && programa.length > 0 ? Number(programa) : null;
      const o: number | null =
        orientacion && orientacion.length > 0 ? Number(orientacion) : null;
      await dispatch(postSincronizarAlumnos({ i, p, o }));
      setIdentificacion("");
      setOrientacion("");
      setPrograma("");
    } catch (error) {
      console.log("error :>> ", error);
    }
    clearConfirmationModal();
    setLoading(false);
  };

  const handleIPOSelected = async (ipo: any) => {
    const carrera = carrerasList.find((carrera: string) => carrera.includes(ipo));
    if (carrera) {
      // Seleccionar la carrera en el buscador de alumnos registrados y actualizar la lista de alumnos
      setLoading(true);
      await dispatch(setCarreraSearch(carrera));
      await dispatch(setEstadoAlumnoSearch("2")); // Estado 2 es INICIALIZADO
      await dispatch(setRegistradosCurrentPage(1));
      await dispatch(getAlumnosRegistrados());
      setLoading(false);
    }
  };

  return (
    <>
      <ModalConfirmation
        show={confirmationModal.show}
        onClose={clearConfirmationModal}
        onConfirm={confirmationModal.onConfirm}
        title={confirmationModal.title}
        message={confirmationModal.dialog}
      />
      {alumnosInicializados && (
        <div className="bg-white p-6 rounded-lg border-2 border-cardBorder col-span-5 grid grid-cols-5">
          <h4 className="font-semibold text-textSecondary mb-3 text-[16px] col-span-5">
            IPOs Estado y cantidad
          </h4>

          <div className="col-span-4 grid grid-cols-5 gap-2 text-xs">
            {alumnosInicializados.map((ipo: any) => (
              <button
                key={ipo.ipo}
                onClick={() => handleIPOSelected(ipo.ipo)}
                className={`rounded-lg bg-[#eee] border-[3px] hover:opacity-70 shadow-md flex flex-nowrap items-center max-h-6 overflow-hidden ${
                  ipo.estado === "INICIALIZADO"
                    ? "bg-primary border-primary"
                    : "border-[#eee]"
                }`}
              >
                <span className="bg-white rounded-md min-w-[55px] text-center font-semibold">
                  {ipo.ipo}
                </span>
                <span
                  title={ipo.estado}
                  className={`px-1 text-[10px] flex-grow whitespace-nowrap truncate overflow-hidden ${
                    ipo.estado === "INICIALIZADO" ? "text-white" : ""
                  }`}
                >
                  {ipo.estado}
                </span>
                <span className="bg-white px-1 rounded-full">
                  {ipo.cantidad}
                </span>
              </button>
            ))}
          </div>

          <div className="col-span-1 pl-4 flex flex-col gap-2">
            <button
              className="text-textPrimary font-bold hover:underline border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
              onClick={ObtenerAlumnosInicializados}
            >
              Actualizar
            </button>

            <button
              className="text-textPrimary font-bold hover:underline border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
              onClick={() => {
                setConfirmationModal({
                  show: true,
                  onConfirm: BorrarTodosLosAlumnos,
                  title: `¿Desea borrar todos los alumnos?`,
                  dialog: "",
                });
              }}
            >
              Borrar Todos
            </button>
          </div>
        </div>
      )}

      <SlDrawer
        label="Inicializar alumnos"
        open={drawerInicializar}
        onSlAfterHide={() => dispatch(setDrawerInicializar(false))}
      >
        <div className="">
          <div className="space-y-2">
            <p>
              Ingrese uno o varios mails de alumno separados por , para
              inicializarlos
            </p>
            <Input
              className="w-full"
              placeholder="alumno@mail.utdt, alumno2@mail.utdt"
              onChange={(text) => setEmail(text)}
            />
            <Button
              className="w-full mt-2"
              onClick={() => {
                setConfirmationModal({
                  show: true,
                  onConfirm: handleInicializarAlumno,
                  title: "¿Desea inicializar vía mails?",
                  dialog: "",
                });
              }}
              label="Inicializar"
            />
          </div>

          <div className="mt-10 space-y-2">
            <p>Inicializar por Identificacion, Programa, Orientacion</p>
            <div className="flex items-center justify-center w-full gap-2">
              <Input
                className="ipoField w-14"
                placeholder="I"
                onChange={(text) => setIdentificacion(text)}
              />
              <span>-</span>
              <Input
                className="ipoField w-14"
                placeholder="P"
                onChange={(text) => setPrograma(text)}
              />
              <span>-</span>
              <Input
                className="ipoField w-14"
                placeholder="O"
                onChange={(text) => setOrientacion(text)}
              />
            </div>
            <div className="flex gap-2">
              <SlButton
                outline
                variant="default"
                size="small"
                className="w-1/3"
                onClick={() => {
                  setConfirmationModal({
                    show: true,
                    onConfirm: SincronizarAlumnos,
                    title: `¿Desea inicializar alumnos con el IPO ${identificacion}-${programa}-${orientacion} ?`,
                    dialog: "",
                  });
                }}
              >
                Inicializar
              </SlButton>
              {/* ................................. */}
              <SlButton
                outline
                variant="primary"
                size="small"
                className="w-1/3"
                onClick={() => {
                  setConfirmationModal({
                    show: true,
                    onConfirm: ResetearIPO,
                    title: `¿Desea resetear alumnos con el IPO ${identificacion}-${programa}-${orientacion} ?`,
                    dialog: "",
                  });
                }}
              >
                {/* <SlIcon
                slot="prefix"
                name="gear"
                style={{ fontSize: "20px" }}
              ></SlIcon> */}
                Resetear
              </SlButton>
              {/* ................................. */}
              <SlButton
                outline
                variant="danger"
                size="small"
                className="w-1/3"
                onClick={() => {
                  setConfirmationModal({
                    show: true,
                    onConfirm: BorrarIPO,
                    title: `¿Desea eliminar alumnos con el IPO ${identificacion}-${programa}-${orientacion} ?`,
                    dialog: "",
                  });
                }}
              >
                {/* <SlIcon
                slot="prefix"
                name="gear"
                style={{ fontSize: "20px" }}
              ></SlIcon> */}
                Borrar
              </SlButton>
              {/* ................................. */}
            </div>
            <div className="flex gap-2">
              <SlButton
                outline
                variant="warning"
                size="small"
                className="w-1/2"
                onClick={() => {
                  setConfirmationModal({
                    show: true,
                    onConfirm: InvalidarOfertas,
                    title: `¿Desea invalidar ofertas para el IPO ${identificacion}-${programa}-${orientacion} ?`,
                    dialog: "",
                  });
                }}
              >
                Invalidar ofertas
              </SlButton>
              {/* ................................. */}
              <SlButton
                outline
                variant="danger"
                size="small"
                className="w-1/2"
                onClick={() => {
                  setConfirmationModal({
                    show: true,
                    onConfirm: VencerOfertas,
                    title: `¿Desea vencer ofertas para el IPO ${identificacion}-${programa}-${orientacion} ?`,
                    dialog: "",
                  });
                }}
              >
                Vencer ofertas
              </SlButton>
              {/* ................................. */}
            </div>
          </div>
        </div>
      </SlDrawer>
    </>
  );
}

export default AlumnosInicializados;
