import { useEffect, useState } from "react";
import "./Validaciones.css";
import { useAppDispatch } from "../../app/store";
import { useSelector } from "react-redux";
import {
  selectLoaderPage,
  selectValidaciones,
} from "../../features/Dashboard/Slices/dashboardSlice";
import "./Validaciones.css";
import ModalConfirmation from "../../components/reusable/ModalConfirmation/ModalConfirmation";
import { dashboardAPI } from "../../Api/dashboardAPI";
import { toastError, toastSuccess } from "../../utils/toast";
import {
  getInicializarValidaciones,
  getValidaciones,
} from "../../features/Dashboard/AsyncThunks/validaciones/getValidaciones";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlDrawer from "@shoelace-style/shoelace/dist/react/drawer";
import SlSwitch from "@shoelace-style/shoelace/dist/react/switch";

function Validaciones() {
  const dispatch = useAppDispatch();
  const loading = useSelector(selectLoaderPage);
  const validaciones = useSelector(selectValidaciones);
  const [validationIdToEdit, setValidationIdToEdit] = useState<null | number>(
    null
  );
  const [mensaje, setMensaje] = useState<string | null>("");
  const [estado, setEstado] = useState<string | null>("");
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [activaValue, setActivaValue] = useState("");
  const [configuracionValue, setConfiguracionValue] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [validacionesOrdenadas, setValidacionesOrdenadas] = useState<any[]>([]);

  useEffect(() => {
    const ordenadas = [...validaciones].sort((a, b) => a.id - b.id);
    setValidacionesOrdenadas(ordenadas);
  }, [validaciones]);

  const obtenerValidaciones = async () => {
    setValidationIdToEdit(null);
    await dispatch(getValidaciones());
    clearNewValuesSaved();
  };

  const inicializarValidaciones = async () => {
    setValidationIdToEdit(null);
    await dispatch(getInicializarValidaciones());
    clearNewValuesSaved();
  };

  const clearNewValuesSaved = () => {
    setActivaValue("");
    setConfiguracionValue("");
  };

  const prepareUpdateValidacionBody = () => {
    let activa;
    let configuracion;
    const validacionToEdit = validaciones.find(
      (v) => v.id === validationIdToEdit
    );
    if (!validacionToEdit) {
      throw new Error("No se encuentra validacion");
    } else {
      if (configuracionValue.length) {
        if (configuracionValue === "null") {
          configuracion = "";
        } else {
          configuracion = configuracionValue;
        }
      } else {
        configuracion = String(validacionToEdit.configuracion);
      }

      if (activaValue.length) {
        if (activaValue === "null") {
          activa = "";
        } else {
          activa = activaValue;
        }
      } else {
        activa = String(validacionToEdit.activa);
      }
    }

    return {
      activa,
      configuracion,
      id: String(validationIdToEdit || ""),
    };
  };

  const actualizarValidacionParametrizada = async (
    prendida: string,
    mensaje: string,
    id: string
  ) => {
    try {
      if (id) {
        await dashboardAPI.updateValidacion(prendida, mensaje, id);
        toastSuccess(
          `Validación con id = ${validationIdToEdit} actualizada con éxito!`
        );
      }
    } catch (error: any) {
      toastError(error?.message || "");
    } finally {
      closeModalConfirmation();
      obtenerValidaciones();
    }
  };

  const closeModalConfirmation = () => setShowModalConfirmation(false);

  useEffect(() => {
    obtenerValidaciones();
  }, []);

  const handleSwitchChange = (activa: number, mensaje: string, id: string) => {
    actualizarValidacionParametrizada(activa === 1 ? "0" : "1", mensaje, id);
  };

  return (
    <div className="p-6 grid grid-cols-4 gap-6 min-w-[1000px]">
      <ModalConfirmation
        show={showModalConfirmation}
        onClose={closeModalConfirmation}
        onConfirm={() =>
          actualizarValidacionParametrizada(
            String(estado),
            String(mensaje),
            String(validationIdToEdit)
          )
        }
        title="Actualizar Validacion"
        message={`Seguro que desea actualizar la validación con id = ${validationIdToEdit} ?`}
      />

      <div className="bg-white p-6 rounded-lg col-span-4 border-2 border-cardBorder overflow-x-hidden">
        <div className="flex justify-between mb-3">
          <div className="flex gap-4 items-center">
            <h4 className="font-semibold text-textSecondary text-[16px]">
              Validaciones
            </h4>

            <SlIcon
              style={{ fontSize: "20px" }}
              className={`cursor-pointer font-bold ${
                loading ? "animate-spin" : ""
              }`}
              onClick={obtenerValidaciones}
              name="arrow-clockwise"
            ></SlIcon>
          </div>
          <button
            className="text-textPrimary font-bold hover:underline border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder"
            onClick={inicializarValidaciones}
          >
            Inicializar validaciones
          </button>
        </div>

        {validaciones && (
          <div className="space-y-2">
            {validacionesOrdenadas.map((validacion) => {
              const mensaje = validacion.configuracion.split('"')[3];
              return (
                <div className="flex items-center">
                  <p className="w-[4%]">{validacion.id}</p>
                  <SlSwitch
                    className="w-[6%]"
                    checked={validacion.activa === 1}
                    onClick={() => {
                      setValidationIdToEdit(validacion.id);
                      handleSwitchChange(
                        validacion.activa,
                        validacion.configuracion,
                        String(validacion.id)
                      );
                    }}
                  ></SlSwitch>

                  <p
                    className={`w-[32%] font-semibold ${
                      validacion.activa === 1 ? "" : "text-secondary"
                    }`}
                  >
                    {validacion.descripcion} <span className="text-secondary font-normal">({validacion.tipo})</span>
                  </p>

                  {/* <p className="w-[4%] text-center">({validacion.tipo})</p> */}
                  <p className="w-[54%] bg-[#eee] p-1 px-3 rounded-r-full rounded-bl-full truncate">
                    {mensaje}
                  </p>
                  <SlIcon
                    name="pencil-square"
                    className="w-[4%] text-[14px] cursor-pointer"
                    onClick={() => {
                      setOpenDrawer(true);
                      setValidationIdToEdit(validacion.id);
                      setMensaje(validacion.configuracion);
                      setEstado(validacion.activa === 1 ? "1" : "0");
                    }}
                  ></SlIcon>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <SlDrawer
        label="Editar validacion"
        open={!!validationIdToEdit && openDrawer}
        onSlAfterHide={() => {
          setOpenDrawer(false);
          setValidationIdToEdit(null);
          setMensaje(null);
          setEstado(null);
        }}
      >
        <p>Definir mensaje para la validacion:</p>
        <p className="font-semibold pb-2">
          {
            validaciones.find(
              (validacion) => validationIdToEdit === validacion.id
            )?.descripcion
          }
        </p>
        <textarea
          rows={10}
          className="border-2 w-full rounded-lg rounded-tl-none p-1 bg-grey border-cardBorder"
          value={mensaje || ""}
          // defaultValue={String(validationIdToEdit) || ""}
          // onChange={setActivaValue}
          onChange={(e) => setMensaje(e.target.value)}
        />
        <p className="text-secondary pb-4">
          <small>* Respetar el formato JSON.</small>
        </p>
        <button
          className="text-textPrimary font-bold hover:underline border-2 border-cardBorder px-3 py-1 rounded-lg hover:bg-cardBorder w-full"
          onClick={(e) => {
            setShowModalConfirmation(true);
          }}
        >
          Actualizar
        </button>
      </SlDrawer>
    </div>
  );
}

export default Validaciones;
